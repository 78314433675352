/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

ol {
  list-style-type: lower-alpha;
}

ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
#root {
  font-weight: lighter;
}

body {
  background: #fff;
}

div:focus {
  outline: none;
}

a,
a.primary {
  color: #06466d;
  text-decoration: underline;
}

.react-grid-Container {
  width: 100% !important;
}

fieldset > label {
  display: block;
  margin-bottom: 20px;
  font-weight: bold;
}

.react-router-modal__modal {
  border: none;
  border-radius: 3px;
  height: 80%;
  max-height: 700px;
  width: 80%;
  max-width: 800px;
  overflow: scroll;
}

.react-router-modal__modal.overflowHidden {
  overflow: hidden;
}

.react-router-modal__modal h1,
.react-router-modal__modal h2,
.react-router-modal__modal h3,
.react-router-modal__modal h4,
.react-router-modal__modal h5 {
  color: #111;
}

.workspaces__modal {
  border: none;
  border-radius: 10px;
  max-width: 488px;
  width: 100%;
  min-height: 200px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}

.workspaces--single-sign-on__modal {
  border: none;
  border-radius: 10px;
  max-width: 75%;
  width: 100%;
  min-height: 200px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}

.dd-menu .dd-menu-items ul li > *:not(.dd-item-ignore),
.dd-menu .dd-menu-items ol li > *:not(.dd-item-ignore) {
  padding: 0.5em 0.75em;
}

.__curious_panda .array li {
  position: relative;
}

body {
  font-family: Helvetica;
  font-size: 16px;
  line-height: 1.5;
}

small {
  font-size: 80%;
}

.t-light-navy {
  color: #093b4f;
}

bold,
b {
  font-weight: bold;
}

i {
  font-style: italic;
}

u {
  text-decoration: underline;
}

.clearfix::after,
.row::after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.row {
  margin-bottom: 10px;
}

/* React Redux Toastr overwrites */
.rrt-confirm-holder {
  border-radius: 6px !important;
}

.rrt-message {
  padding: 40px !important;
}

.rrt-button {
  width: 50% !important;
}

.rrt-cancel-btn {
  border-left: 1px solid #f0f0f0 !important;
}

.redux-toastr .toastr.rrt-success {
  background-color: #017a47;
}

.redux-toastr .toastr.rrt-success.toastr-forest-green .rrt-middle-container {
  margin-left: 0;
  float: none;
  padding: 10px;
  width: 80%;
}

.redux-toastr .toastr.rrt-success.toastr-forest-green .close-toastr {
  color: #ffffff;
  opacity: 1;
}

.permissions-matrix-modal {
  width: unset;
  max-width: 1020px;
  max-height: unset;
  overflow: hidden;
}

/* The body content rich text editor's parent element sometimes it gets a higher z-index than the rest of the form */
.email-setting-body-content {
  z-index: 0;
}

.space-y-4 > * {
  margin-top: 4px;
  margin-bottom: 4px;
}

.grid-column-two-thirds {
  width: 66.66666667%;
  float: left;
  padding: 0px 16px;
}

@media (max-width: 769px) {
  .grid-column-two-thirds {
    width: 100%;
  }
  .grid-column-one-thirds {
    width: 100%;
  }
}

.grid-column-one-third {
  width: 33.3333333%;
  float: left;
  padding: 0px 16px;
}

.grid-row {
  margin-left: -16px;
  margin-right: -16px;
}
.grid-row:after {
  clear: both;
  content: '';
  display: block;
}

.modal {
  border: none;
  border-radius: 10px;
  min-width: 612px;
  min-height: 200px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}
